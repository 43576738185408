import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./applications@gen.scss";

const GeneratedProductApplications = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          Applications - PagerDuty正規代理店 - 株式会社Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="全システムを見える化し即応を支援するダッシュボード 「オペレーションコマンドコンソール」"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="全システムを見える化し即応を支援するダッシュボード 「オペレーションコマンドコンソール」"
        />

        <meta
          property="og:title"
          content="Applications - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="全システムを見える化し即応を支援するダッシュボード 「オペレーションコマンドコンソール」"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/applications/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/applications/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="全システムを見える化し即応を支援するダッシュボード 「オペレーションコマンドコンソール」"
        overview=""
      />

      <div className="applicationWrapper">
        <div className="application-container">
          <div className="text-left-1 mt0">
            <div className="text-part">
              <h4 className="title-index">01.</h4>
              <h4 className="title">INFRASTRUCTURE HEALTH MODULE</h4>
              <p className="desc">
                PagerDutyのInfrastructure Health
                Moduleは相関するイベントを時系列で可視化し、インフラストラクチャー全体の健全性を認識できます。これにより、サービス中断時のよくある傾向を見ることができ、解決までの平均時間を短縮し、ビジネスに影響を与える前に、問題を把握できます。
              </p>
              <ul className="list-container">
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  パターン、傾向、異常を特定
                </li>
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  インシデントのビジネスへの影響と重大性を評価
                </li>
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  迅速な問題特定のためデータ ソースまたはサービス毎にプロット
                </li>
              </ul>
            </div>
            <div className="img-container">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Applications/application_img_1_ed45c2bf4e.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672994156937&tr=w-720,q-100,f-webp"
                alt="INFRASTRUCTURE HEALTH MODULE"
              />
            </div>
          </div>
          <div className="text-left-1">
            <div className="text-part">
              <h4 className="title-index">02.</h4>
              <h4 className="title">SERVICE HEALTH MODULE</h4>
              <p className="desc">
                PagerDutyのService Health
                Moduleは即時に選別・優先順位決定が必要なサービス、インシデント対応が進行中のサービス、オープンされたインシデントのない正常な状態のサービスを特定します。PagerDutyが持つ洞察力によって負荷が軽減されるため、IT
                チームは自社のデジタル
                ビジネスの全体的な健全性をすばやく把握することが可能です。
              </p>
              <ul className="list-container">
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  自社のインフラストラクチャーの横断的なサービス検索
                </li>
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  自社サービス全体のステータスを統合したビュー
                </li>
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  関連するインシデントを簡単に表示して、インフラストラクチャー全体に及ぶ状況を取得
                </li>
              </ul>
            </div>
            <div className="img-container">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Applications/application_img_2_4acf2edab1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672994156745&tr=q-100,w-720,f-webp"
                alt="SERVICE HEALTH MODULE"
              />
            </div>
          </div>
          <div className="text-left-1">
            <div className="text-part">
              <h4 className="title-index">03.</h4>
              <h4 className="title">RESPONDERS MODULE</h4>
              <p className="desc">
                PagerDutyのResponders
                Moduleは適切な関係者を集め、対象となる問題の組織内のエキスパートと連携し、オープンされているインシデントをより迅速に解決します。密接な連携という面で、ビジネス
                ステークホルダーは、継続的なアップデート情報やコミュニケーションの流れを通して、緊急度の高いインシデントに取り掛かっているレスポンダーを識別することができます。
              </p>
              <ul className="list-container">
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  すばやくレスポンダーの連絡先詳細を取得
                </li>
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  すべてのエスカレーション
                  ポリシーとそれに該当するレスポンダーを表示
                </li>
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  オープンされているインシデントの修正に対応しているレスポンダーを識別
                </li>
              </ul>
            </div>
            <div className="img-container">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Applications/application_img_3_143a7d5c78.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672994156811&tr=w-720,q-100,f-webp"
                alt="RESPONDERS MODULE"
              />
            </div>
          </div>
          <div className="text-left-1">
            <div className="text-part">
              <h4 className="title-index">04.</h4>
              <h4 className="title">MAJOR INCIDENTS MODULE</h4>
              <p className="desc">
                PagerDutyのMajor Incidents
                Moduleはリアルタイムで発生する重大なインシデントを表示し、根本原因の迅速な分析と解決に向けて、影響を受けているサービスとインフラストラクチャーの関係を特定します。
              </p>
              <ul className="list-container">
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  優先順位付けされたインシデントの一覧を表示
                </li>
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  特定のインシデントにフォーカスし、関連するより多くの情報を入手
                </li>
                <li className="list-items">
                  <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                  関連するインシデントをすばやく表示し、インフラストラクチャー全体の状況を把握
                </li>
              </ul>
            </div>
            <div className="img-container">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Applications/application_img_4_5724f1e90d.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672994156769&tr=w-720,q-100,f-webp"
                alt="MAJOR INCIDENTS MODULE"
              />
            </div>
          </div>
          <div className="conclusion-container">
            <h6 className="conclusion-text">
              Operations Command Console
              は、将来的にも非常に期待できるPagerDutyの拡張機能です。
              <br />
              監視ツールや、ノイズの直接の関連性を統合したビューを提供し、
              <br />
              より適切にサービスを管理してサービスの停止状態を防ぐのに役立ちます。
              <br />
              Operations Command Console
              のおかげで、問題が実際に起こる前に、予測することが可能です。
            </h6>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductApplications;
